import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../assets/accelehealth-email-signature-logo.svg";

export const LogoWithText = () => {
  return (
    <Container fluid>
      <Row noGutters>
        <Col>
          <Container fluid>
            <Row>
              <Col className="d-flex justify-content-start d-md-block">
                  <Image fluid src={logo} alt="" loading="lazy" />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
