import React, { useState } from "react";
import styled from "styled-components";
import { SubmissionForm } from "./Form";
import image from "../../assets/grayshape.png";
import SuccessfulSubmit from "./SuccessfulSubmit";
import LoadingIcon from "./LoadingIcon";
import { Col, Container, Row } from "react-bootstrap";

const ContactUsCopy = styled.p`
  font-family: Raleway, sans-serif;
  color: #33526e;
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;
`;

const ContactUsCopySmall = styled.p`
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  line-height: 24px;
`;

const ColWithBackgroundImage = styled(Col)`
  background-image: url(${image});
  background-repeat: no-repeat;
  background-position: right;
`;
const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Row noGutters className="mt-5" id="contact-us">
        <ColWithBackgroundImage
          xs={12}
          md={7}
          className="mb-5 d-flex align-items-center"
        >
          <Container fluid style={{ height: "400px" }}>
            <Row className="d-flex justify-content-center mt-5">
              <Col xs={12} lg={10}>
                <ContactUsCopy>
                  Interested in advancing your innovative solution with
                  AcceleHealth?
                </ContactUsCopy>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col xs={12} lg={10}>
                <ContactUsCopySmall>
                  Fill out this form and upload your pitch deck if available.
                  We’ll review and get back to you as soon as possible.
                </ContactUsCopySmall>
              </Col>
            </Row>
          </Container>
        </ColWithBackgroundImage>
        <Col xs={12} md={5} className="mb-md-5" id="form-column">
          {loading ? (
            <LoadingIcon />
          ) : submitted ? (
            <SuccessfulSubmit setSubmitted={setSubmitted} />
          ) : (
            <SubmissionForm
              setSubmitted={setSubmitted}
              setLoading={setLoading}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ContactUs;
