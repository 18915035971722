import React from "react";
import styled from "styled-components";

const StyledLoadingIcon = styled.section`
  .submitting {
    margin-left: 20px;
    font-size: 24px;
    font-family: Raleway;
    color: #33526e;
  }
`;

const LoadingIcon = () => {
  return (
    <StyledLoadingIcon>
      <div className="spinner-border" role="status">
        <span className="sr-only">Submitting...</span>
      </div>
    </StyledLoadingIcon>
  );
};

export default LoadingIcon;
