import React, { Dispatch, SetStateAction } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SuccessfulSubmitParagraph = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 150%;
`

const FillOutAnotherFormLink = styled.a`
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-decoration: underline;
`

const SuccessfulSubmit: React.FC<SuccessfulSubmitProps> = ({
  setSubmitted,
}) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    setSubmitted(false);
  };

  return (
    <Container className="d-flex flex-column justify-content-center" fluid>
      <Row>
          <Col>
            <SuccessfulSubmitParagraph>
              Your form has been submitted!
          </SuccessfulSubmitParagraph>
          </Col>
      </Row>
      <Row>
        <Col>
          <FillOutAnotherFormLink href="#" onClick={handleClick}>
            Fill out another form
          </FillOutAnotherFormLink>
        </Col>
      </Row>
    </Container>
  );
};

interface SuccessfulSubmitProps {
  setSubmitted: Dispatch<SetStateAction<boolean>>;
}

export default SuccessfulSubmit;
