import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { LogoWithText } from "../components/LogoWithText";
import { Slogan } from "../components/Slogan";

import subwayTube from "../assets/subway-tube.png";
import subwayTubeCenter from "../assets/subway-tube-center.png";

const Hero = () => {
  return (
    <>
      <Container fluid id="home">
        <Row noGutters className="mb-5">
          <Col md={{ offset: 3, span: 6 }}>
            <LogoWithText />
          </Col>
        </Row>
        <Row noGutters>
          <Col md={{ offset: 3, span: 6 }}>
            <Slogan />
          </Col>
        </Row>
      </Container>
      <Image className="d-none d-md-flex mt-5" src={subwayTube} fluid></Image>
      <Image className="d-md-none mt-5" src={subwayTubeCenter} fluid></Image>
    </>
  );
};

export default Hero;
