import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AboutUs from "./components/AboutUs";
import Accelerator from "./components/accelerator/Accelerator";
import ContactUs from "./components/contact-us/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Partners from "./components/Partners";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const RowWith150pxMarginTop = styled(Row)`
  margin-top: 150px;
`;

const RowWith300pxMarginTop = styled(Row)`
  margin-top: 300px;
`;

const HeroWithMarginTopContainer = styled.div`
  margin-top: 150px;
`

const App = () => {
  return (
    <>
      {/* The above container has the padding and margin top to account for the fixed
      navbar before the page has been scrolled*/}
      <Header />
      <HeroWithMarginTopContainer>
        <Hero />
      </HeroWithMarginTopContainer>
      <Container className="mt-5" fluid>
        <Row noGutters style={{ marginTop: "150px" }}>
          <Col xs={12} className="">
            <AboutUs />
          </Col>
        </Row>
        <Row noGutters style={{ marginBottom: "50px" }}>
          <Col xs={12}>
            <Partners />
          </Col>
        </Row>
      </Container>

      <RowWith150pxMarginTop noGutters>
        <Col>
          <Accelerator />
        </Col>
      </RowWith150pxMarginTop>

      <Container className="mb-5" fluid>
        <RowWith300pxMarginTop noGutters className="mb-5" id="contact-us-row">
          <Col>
            <ContactUs />
          </Col>
        </RowWith300pxMarginTop>
      </Container>

      <Footer/>
    </>
  );
};

export default App;
