import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import dotenv from "dotenv";
import { Button, Col, Row } from "react-bootstrap";

const StyledForm = styled.section`
  div.input-block {
    position: relative;
  }

  div.input-block span.placeholder {
    position: absolute;
    margin: 13px 0;
    padding: 0 4px;
    display: flex;
    align-items: center;
    top: 0;
    left: 17px;
    transition: all 0.2s;
    transform-origin: 0% 0%;
    background: none;
    pointer-events: none;
    font-family: Quicksand;
    font-size: 16px;
    line-height: 150%;
  }

  div.input-block input {
    color: #495055;
    padding: 15px 15px;
    outline: none;
    background: white;
    border: 1px solid #667d92;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 25%;
  }

  div.input-block input:hover {
    background: #f5f6f8;
  }

  div.input-block input:focus + span.placeholder,
  div.input-block input:valid + span.placeholder {
    transform: scale(0.8) translateY(-30px);
    background: #fff;
  }

  input:focus small.form-text,
  input:form-control:valid + small.form-text {
    transform: scale(0.8) translateY(-30px);
    background: #fff;
  }

  .submit-button {
    position: relative;
    left: 279px;
    display: block;
    width: 137px;
    height: 42px;
    border: 1px none #000;
    border-radius: 4px;
    background-color: #9cd8e9;
    font-family: Quicksand, sans-serif;
    color: #000;
    font-size: 15px;
    line-height: 18.75px;
    font-weight: 500;
    text-align: center;
    -o-object-fit: none;
    object-fit: none;
  }

  .file-button {
    display: center;
    width: 42px;
    height: 42px;
    margin-top: 24px;

    border: 1px none #000;
    border-radius: 24px;
    background-color: #9cd8e9;
    -o-object-fit: none;
    object-fit: none;
    font-size: 30px;
    font-weight: 100;
    font-family: Quicksand, sans-serif;
  }

  .file-text {
    font-size: 18px;
    line-height: 18.75px;
    font-weight: 500;
    font-family: Quicksand, sans-serif;
  }

  .file-cta {
    :hover .circle-button {
      color: #ffffff;
      background-color: #2d9ebc;
    }
    :hover .file-text {
      color: #2d9ebc;
      text-decoration: underline;
    }
  }

  .helpful-text {
    font-size: 12px;
    line-height: 18.75px;
    font-weight: 500;
    font-family: Quicksand, sans-serif;
  }

  .required {
    border-color: #ff0000;
  }
`;

dotenv.config();

// TODO - Commenting to remove TS warnings
/*const InputBlock = styled.div`
  position: relative;
`*/

// TODO - Commenting to remove TS warnings
/*const PlaceHolderSpan = styled.span`
  position: absolute;
  margin: 13px 0;
  padding: 0 4px;
  display: flex;
  align-items: center;
  top: 0;
  left: 17px;
  transition: all 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
  font-family: Quicksand;
  font-size: 16px;
  line-height: 150%;
`*/

const CircleFileInputButton = styled.div`
  width: 42px;
  height: 42px;
  border: 1px none #000;
  border-radius: 24px;
  background-color: #9cd8e9;
  font-size: 28px;
  font-weight: 100;
  font-family: Quicksand, sans-serif;
`;

const SubmitButton = styled(Button)`
  border: 1px none #000;
  border-radius: 4px;
  background-color: #9cd8e9;
  color: #000;
  font-size: 15px;
  line-height: 18.75px;
  font-weight: 500;
  width: 137px;
  height: 42px;
  :hover {
    color: #ffffff;
    background-color: #2d9ebc;
  }
`;
export const SubmissionForm: React.FC<FormProps> = ({
  setSubmitted,
  setLoading,
}) => {
  const envId = process.env.REACT_APP_EMAILJS_USER_ID || "x";
  const fileField = React.useRef<HTMLInputElement>(null);
  emailjs.init(envId);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const [attatchmentUrl, setAttachmentUrl] = useState("");

  const [nameBorder, setNameBorder] = useState({});
  const [companyBorder, setCompanyBorder] = useState({});
  const [emailBorder, setEmailBorder] = useState({});

  const [nameColor, setNameColor] = useState({});
  const [companyColor, setCompanyColor] = useState({});
  const [emailColor, setEmailColor] = useState({});

  const templateParams = {
    name: name,
    company: company,
    email: email,
    website: website,
    content: attatchmentUrl,
  };

  const handleFieldChange = (e: any) => {
    const { value } = e.target;
    const { target } = e;
    switch (target.name) {
      case "name":
        setName(value);
        setNameBorder({});
        setNameColor({});
        break;
      case "company":
        setCompany(value);
        setCompanyBorder({});
        setCompanyColor({});
        break;
      case "email":
        setEmail(value);
        setEmailBorder({});
        setEmailColor({});
        break;
      case "website":
        setWebsite(value);
        break;
    }
  };

  const handleFieldClick = (e: any) => {
    e.preventDefault();
    if (name === "") {
      setNameBorder({
        borderColor: "#ff0000",
      });
      setNameColor({
        color: "#ff0000",
      });
    }
    if (company === "") {
      setCompanyBorder({
        borderColor: "#ff0000",
      });
      setCompanyColor({
        color: "#ff0000",
      });
    }
    if (!validateEmail(email)) {
      setEmailBorder({
        borderColor: "#ff0000",
      });
      setEmailColor({
        color: "#ff0000",
      });
    }

    if (name !== "" && company !== "" && validateEmail(email)) {
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    emailjs.send("default_service", "contact_form", templateParams).then(
      () => {
        setLoading(false);
        setSubmitted(true);
      },
      () => {
        setLoading(false);
        alert(
          "Sorry, there was an error submitting your information - please try again."
        );
      }
    );
  };

  const handleFileClick = (e: any) => {
    fileField?.current!.click();
  };

  const handleFileChange = async (e: any) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      setAttachment(fileUploaded);
      const dataUrl = (await toBase64(fileUploaded)) as string;
      setAttachmentUrl(dataUrl);
    }
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <>
      {/*
  TODO - Refactor when more time allows
  <form id="email-form" name="email-form" data-name="Email Form">
    <Container fluid>
      <Row>
        <Col>
          <InputBlock>
            <input
                type="text"
                name="name"
                value={name}
                onChange={handleFieldChange}
                id="name"
                required
                style={nameBorder}
              />
          </InputBlock>
          <PlaceHolderSpan style={nameColor}>
            *name
          </PlaceHolderSpan>
        </Col>
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
    </Container>
  </form>*/}
      <Row className="mb-5" noGutters id="form-row">
        <Col xs={{ offset: 12 }} xl={{ offset: 1, span: 10 }}>
          <StyledForm>
            <form
              className="w-100"
              id="email-form"
              name="email-form"
              data-name="Email Form"
            >
              <div className="input-block">
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleFieldChange}
                  id="full-name"
                  required
                  style={nameBorder}
                />
                <span className="placeholder" style={nameColor}>
                  *Full Name
                </span>
              </div>
              <br />
              <div className="input-block">
                <input
                  type="text"
                  name="company"
                  value={company}
                  onChange={handleFieldChange}
                  required
                  style={companyBorder}
                />
                <span className="placeholder" style={companyColor}>
                  *Company
                </span>
              </div>
              <br />
              <div className="input-block">
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={handleFieldChange}
                  required
                  style={emailBorder}
                />
                <span className="placeholder" style={emailColor}>
                  *Email
                </span>
              </div>
              <br />
              <div className="input-block">
                <input
                  type="text"
                  name="website"
                  value={website}
                  onChange={handleFieldChange}
                  required
                />
                <span className="placeholder">Website</span>
              </div>
              <br />
              <Row>
                <Col xs={7}>
                  <input
                    id="fileid"
                    type="file"
                    hidden
                    ref={fileField}
                    onChange={handleFileChange}
                    accept=".pdf"
                  />
                  <div className="file-cta">
                    <Row noGutters>
                      <Col xs={3}>
                        <CircleFileInputButton
                          onClick={handleFileClick}
                          className="mr-0 d-flex justify-content-center align-items-center circle-button"
                        >
                          <span>+</span>
                        </CircleFileInputButton>
                      </Col>
                      <Col xs={9}>
                        {attachment ? (
                          <span className="file-text" onClick={handleFileClick}>
                            {attachment.name}
                          </span>
                        ) : (
                          <>
                            <span
                              className="file-text"
                              onClick={handleFileClick}
                            >
                              Choose File
                            </span>
                            <br />
                            <span className="helpful-text">
                              PDF file, under 2mb
                            </span>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col className="d-flex align-items-center" xs={5}>
                  <SubmitButton onClick={handleFieldClick}>Apply</SubmitButton>
                </Col>
              </Row>
            </form>
          </StyledForm>
        </Col>
      </Row>

      {/*<Form>
              <FormGroup controlId="formBasicEmail">
                <FormControl type="email" placeholder="Enter email" />
                <FormText className="text-muted">
                  Test
                </FormText>
              </FormGroup>

              <FormGroup controlId="formBasicPassword">
                <FormControl type="password" placeholder="Password" />
              </FormGroup>
              <FormGroup controlId="formBasicCheckbox">
                <FormCheck type="checkbox" label="Check me out" />
              </FormGroup>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>*/}
    </>
  );
};

interface FormProps {
  setSubmitted: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
