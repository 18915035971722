import React from "react";
import { Navbar } from "react-bootstrap";
import styled from "styled-components";

const FooterNavbar = styled(Navbar)`
  background-color: #667d92;
`;

const FooterText = styled.p`
  font-family: Quicksand, sans-serif;
  color: #f5f6f8;
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  bottom: 0;
`;

const FooterTextMobile = styled.p`
  font-family: Quicksand, sans-serif;
  color: #f5f6f8;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;
const Footer = () => {
  return (
    <FooterNavbar className="py-3">
        <FooterText className="d-none d-md-block m-auto">AcceleHealth copyright 2021</FooterText>
        <FooterTextMobile className="d-md-none m-auto">AcceleHealth copyright 2021</FooterTextMobile>
    </FooterNavbar>

  );
};

export default Footer;
