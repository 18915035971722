import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import ascend from "../assets/ascend-logo.svg";
import dayton from "../assets/dayton-childrens-logo.svg";
import hospice from "../assets/hospice-logo.svg";
import kettering from "../assets/kettering-logo.svg";
import premier from "../assets/premier-logo.svg";

const PartnersContainer = styled(Container)`
  .partners-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .partners-header-text {
    font-family: Raleway, sans-serif;
    font-size: 11px;
    line-height: 16.5px;
    font-weight: 600;
    letter-spacing: 0.2em;
  }

  .partners-icons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 19px;
    margin-bottom: 60px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .ascend-logo {
    width: 137px;
    height: 48px;
  }

  .childrens-logo {
    overflow: visible;
    width: 242px;
    height: 39px;
  }

  .hospice-logo {
    width: 181px;
    height: 54px;
  }

  .kettering-logo {
    width: 153px;
    height: 42px;
  }

  .premier-logo {
    width: 208px;
    height: 41px;
  }
`;

const Partners = () => {
  return (
    <PartnersContainer fluid>
      <Row>
        <Col xs={12}>
          <div className="partners-header">
            <p className="partners-header-text">TRUSTED PARTNERS</p>
          </div>
        </Col>
      </Row>
      <Row className="partners-icons">
        <Col xs={12} lg={2} className="d-flex justify-content-center mb-5">
          <img src={ascend} alt="" className="ascend-logo" />
        </Col>
        <Col xs={12} lg={2} className="d-flex justify-content-center mb-5">
          <img src={dayton} alt="" className="childrens-logo" />
        </Col>
        <Col xs={12} lg={2} className="d-flex justify-content-center mb-5">
          <img src={hospice} alt="" className="hospice-logo" />
        </Col>
        <Col xs={12} lg={2} className="d-flex justify-content-center mb-5">
          <img src={kettering} alt="" className="kettering-logo" />
        </Col>
        <Col xs={12} lg={2} className="d-flex justify-content-center mb-5">
          <img src={premier} alt="" className="premier-logo" />
        </Col>
      </Row>
    </PartnersContainer>
  );
};

export default Partners;
