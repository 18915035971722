import React from "react";
import styled from "styled-components";
import textLogo from "../assets/accelehealth-horizontal.svg";
import logo from "../assets/acceleA.svg";
import { Navbar, Nav } from "react-bootstrap";

const StyledHeader = styled.section`
  .navbar-ah {
    max-height: 50px
    z-index: 9999;
  }

  .scroll {
    box-shadow: 0 9px 11px 5px rgba(0, 0, 0, 0.09);
  }

  .contact-us-link {
    font-family: Raleway, sans-serif;
    color: #5abeda;
    font-size: 15px;
    line-height: 18px;
    height: 18px;
    font-weight: 800;
    letter-spacing: 0.20em;
    :hover {
      color: #2d9ebc;
      text-decoration: underline;
    }
  }
`;

const Header = () => {
  window.addEventListener("scroll", (e) => {
    const nav = document.querySelector(".navbar-ah");
    if (window.pageYOffset > 0) {
      nav?.classList.add("scroll");
    } else {
      nav?.classList.remove("scroll");
    }
  });

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <StyledHeader>
      <Navbar bg="white" fixed="top" className="navbar-ah">
        <Navbar.Brand href="#home" onClick={handleLogoClick}>
          <img alt="" src={logo} height="30" className="d-lg-none" />{" "}
          <img
            alt=""
            src={textLogo}
            height="30"
            className="navbar-logo d-none d-lg-flex"
          />{" "}
        </Navbar.Brand>
        <Nav className="ml-auto">
          <a href="#contact-us" className="contact-us-link">
            Contact Us
          </a>
        </Nav>
      </Navbar>
    </StyledHeader>
  );
};

export default Header;
