import React, { FunctionComponent } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import capitalImage from "../../assets/capital.png";
import expertsImage from "../../assets/experts.png";
import hospitalImage from "../../assets/hospital.png";

const AcceleratorContainer = styled(Container)`
  // slanted edges here
  .quote {
    background: #def2f8;
    position: relative;
    z-index: -1;

    &:before,
    &:after {
      background: inherit;
      content: "";
      display: block;
      height: 250px;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      padding-bottom: 1400px;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    &:before {
      top: 0;
      transform: skewY(-5deg);
      transform-origin: top left;
    }

    &:after {
    }
  }

  // demo code below
  .quote {
    color: #fff;
    font-family: "Fira Sans", sans-serif;
    padding: 1% 20px 1% 20px;
    text-align: center;
  }

  // slanted edges here
  .quote2 {
    background: #def2f8;
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      background: inherit;
      content: "";
      display: block;
      height: 150px;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    &:after {
      transform: skewY(-5deg);
      transform-origin: top right;
    }
  }

  // demo code below
  .quote2 {
    color: #fff;
    font-family: "Fira Sans", sans-serif;
    padding: 1% 20px 1% 20px;
    text-align: center;
  }

  // slanted edges here
  .quote3 {
    background: #def2f8;
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      background: inherit;
      content: "";
      display: block;
      height: 175px;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    &:after {
      transform: skewY(-5deg);
      transform-origin: top right;
    }
  }

  // demo code below
  .quote3 {
    color: #fff;
    font-family: "Fira Sans", sans-serif;
    text-align: center;
  }
`;

const AcceleratorRow = styled(Row)`
  background-color: #def2f8;
`;

const AcceleratorHeaderTextMobile = styled.p`
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  font-family: raleway;
  color: #33526e;
`;

const AcceleratorHeaderTextDesktop = styled.p`
  font-size: 37px;
  font-weight: 500;
  line-height: 55.5px;
  font-family: raleway;
  color: #33526e;
`;

const AcceleratorTextDesktop = styled.p`
  font-family: quicksand;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
`;

const AcceleratorTextMobile = styled.p`
  font-family: quicksand;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`;

const BodyTextHeadingDesktop = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  font-family: raleway;
  color: #33526e;
`;
const BodyTextHeadingMobile = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  font-family: raleway;
  color: #33526e;
`;

const BodyText = styled.span`
  font-family: quicksand;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

type BodyTextWithHeadingProps = {
  heading: string;
  verticalCenter: boolean;
};

const BodyTextWithHeading: FunctionComponent<BodyTextWithHeadingProps> = (
  props
) => {
  return (
    // <Container
    //   className="h-100 d-flex flex-column justify-content-center"
    //   fluid
    // >
    <>
      <Row className="mb-3" noGutters>
        <Col>
          <BodyTextHeadingMobile className="d-lg-none">
            {props.heading}
          </BodyTextHeadingMobile>
          <BodyTextHeadingDesktop className="d-none d-lg-block">
            {props.heading}
          </BodyTextHeadingDesktop>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <BodyText>{props.children}</BodyText>
        </Col>
      </Row>
      {/* </Container> */}
    </>
  );
};

const NotYourTypicalRow = ({
  imageUrl,
  heading,
  body,
  addMarginBottom,
  imageFirst,
}: {
  imageUrl: string;
  heading: string;
  body: string;
  addMarginBottom: boolean;
  imageFirst: boolean;
}) => {
  return (
    <>
      {imageFirst ? (
        <AcceleratorRow className="d-none d-md-flex">
          <Col
            xs={{ offset: 1, span: 10 }}
            md={{ offset: 1, span: 5 }}
            lg={{ offset: 2, span: 4 }}
            className={`${addMarginBottom ? " mb-5" : ""}`}
          >
            <Image fluid src={imageUrl} />
          </Col>
          <Col
            xs={12}
            md={5}
            lg={4}
            className={`d-flex ${addMarginBottom ? " mb-5" : ""}`}
          >
            <Row>
              <Col
                className="d-flex flex-column justify-content-center"
                md={12}
                lg={10}
              >
                <BodyTextWithHeading heading={heading} verticalCenter={true}>
                  {body}
                </BodyTextWithHeading>
              </Col>
            </Row>
          </Col>
        </AcceleratorRow>
      ) : (
        <AcceleratorRow className="d-none d-md-flex">
          <Col
            xs={12}
            md={{ offset: 1, span: 5 }}
            lg={{ offset: 2, span: 4 }}
            className={`d-flex flex-column justify-content-center ${
              addMarginBottom ? " mb-5" : ""
            }`}
          >
            <Row className="" noGutters>
              <Col className="" md={12} lg={10}>
                <BodyTextWithHeading heading={heading} verticalCenter={true}>
                  {body}
                </BodyTextWithHeading>
              </Col>
            </Row>
          </Col>
          <Col xs={10} md={4} className={`${addMarginBottom ? " mb-5" : ""}`}>
            <Image fluid src={imageUrl} />
          </Col>
        </AcceleratorRow>
      )}
      <AcceleratorRow className="d-md-none">
        <Col
          xs={{ offset: 1, span: 10 }}
          md={{ offset: 2, span: 4 }}
          className={`mt-5`}
        >
          <Image fluid src={imageUrl} />
        </Col>
        <Col xs={12} md={5} lg={4} className={`mt-5`}>
          <BodyTextWithHeading heading={heading} verticalCenter={true}>
            {body}
          </BodyTextWithHeading>
        </Col>
      </AcceleratorRow>
    </>
  );
};

const Accelerator = () => {
  return (
    <AcceleratorContainer fluid id="not-typical">
      <Row className="quote"></Row>
      <AcceleratorRow className="mt-5">
        <Col xs={{ offset: 1, span: 10 }} md={{ offset: 3, span: 6 }}>
          <AcceleratorHeaderTextMobile className="d-lg-none text-center">
            We’re not your typical accelerator
          </AcceleratorHeaderTextMobile>
          <AcceleratorHeaderTextDesktop className="d-none d-lg-block text-center">
            We’re not your typical accelerator
          </AcceleratorHeaderTextDesktop>
        </Col>
      </AcceleratorRow>
      <AcceleratorRow className="pb-4">
        <Col xs={12} md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          <AcceleratorTextDesktop className="d-none d-lg-block">
            AcceleHealth drives our partner companies toward their goals by
            offering unique relationships and unrivaled access in three key
            areas:
          </AcceleratorTextDesktop>
          <AcceleratorTextMobile className="d-lg-none">
            AcceleHealth drives our partner companies toward their goals by
            offering unique relationships and unrivaled access in three key
            areas:
          </AcceleratorTextMobile>
        </Col>
      </AcceleratorRow>
      <NotYourTypicalRow
        imageUrl={capitalImage}
        heading="Access to Capital"
        body="Raise capital through access to an array of funding mechanisms."
        addMarginBottom={true}
        imageFirst={true}
      />
      <NotYourTypicalRow
        imageUrl={expertsImage}
        heading="Access to Experts"
        body="Drive product expansion and refinement
              through a collaborative team of resources with
              expertise in healthcare management, product
              market research and validation, data science
              and analytics, UX/UI strategy and design, and
              web, app, and product development."
        addMarginBottom={true}
        imageFirst={false}
      />
      <NotYourTypicalRow
        imageUrl={hospitalImage}
        heading="Access to Hospitals and Healthcare Facilities"
        body="Test, evaluate, and validate your idea through unparalleled access to hospital network personnel, advisory boards, healthcare facilities, and patient, clinical, and payer data."
        addMarginBottom={false}
        imageFirst={true}
      />
      <Row className="quote2 d-xl-none"></Row>
      <Row className="quote3 d-none d-xl-block"></Row>
    </AcceleratorContainer>
  );
};

export default Accelerator;
