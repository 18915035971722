import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SloganText = styled.p`
  font-size: 40px;
  font-family: Raleway, sans-serif;
  color: #33526e;
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
`;

export const Slogan = () => {
  return (
    <Container fluid>
      <Row noGutters>
        <Col>
          <Container fluid>
            <Row>
              <Col>
                <SloganText>
                  Driving early-stage healthcare, wellness, &amp; human
                  performance solutions to market.
                </SloganText>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
