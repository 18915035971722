import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import image from "../assets/about-us.png";

const HeadingParagraph = styled.p`
  font-family: Raleway, sans-serif;
  color: #33526e;
  font-size: 32px;
  line-height: 37.57px;
  font-weight: 500;
`;

const BodyParagraph = styled.p`
  padding-bottom: 10px;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  line-height: 27px;
`;
// const BodyHighlightSpan = styled.span`
//   background-color: #def2f8;
//   display: inline-block;
//   mix-blend-mode: multiply;
//   transform: skew(-40deg);
//   margin-top: 3px;
// `;
// const HighLightedTextLink = styled.span`
//   display: inline-block;
//   transform: skew(40deg);
//   text-decoration: none;
//   color: #000;
// `;

const AboutUsImage = styled(Image)``;

const AboutUsHeading = () => {
  return (
    <HeadingParagraph>
      We are a collaborative <br />
      commercialization engine
    </HeadingParagraph>
  );
};

const AboutUsLearnMoreLink = styled.a`
  direction: ltr;
  font-family: Raleway, sans-serif;
  color: #5abeda;
  font-size: 15px;
  line-height: 17.61px;
  font-weight: 800;
  text-align: left;
  letter-spacing: 0.2em;
  text-transform: none;
  :hover {
    color: #2d9ebc;
    text-decoration: underline;
  }
`;

const AboutUsBody = () => {
  return (
    <BodyParagraph>
      AcceleHealth advances early-stage healthcare, wellness, and human
      performance innovations and technologies through unparalleled access to
      capital, experts, and a community of hospital networks. We are a
      passionate network with expertise spanning the life cycle of starting and
      commercializing meaningful technologies.
      {/* <BodyHighlightSpan className="d-lg-none">
        <HighLightedTextLink className="pl-1">
          unparalleled access to capital,
        </HighLightedTextLink>
      </BodyHighlightSpan>
      <BodyHighlightSpan className="d-lg-none">
        <HighLightedTextLink className="pr-1">
          experts, and a community
        </HighLightedTextLink>
      </BodyHighlightSpan>
      <BodyHighlightSpan className="d-lg-none">
        <HighLightedTextLink className="d-lg-none">
          of hospital networks.
        </HighLightedTextLink>
      </BodyHighlightSpan>
      <BodyHighlightSpan className="d-none d-lg-flex">
        <HighLightedTextLink>
          unparalleled access to capital, experts, and a community of hospital
          networks.
        </HighLightedTextLink>
      </BodyHighlightSpan>
      <span> </span>
      <span className="d-lg-none">
        We are a passionate network with expertise spanning the life cycle of
        starting and commercializing meaningful technologies.
      </span>
      <span className="d-none d-lg-flex">
        We are a passionate network with expertise spanning the life cycle of
        starting and commercializing meaningful technologies.
      </span> */}
    </BodyParagraph>
  );
};

const AboutUs = () => {
  return (
    <Container id="about-us-container" fluid>
      <Row>
        <Col
          xs={{ offset: 1, span: 10 }}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 1 }}
          className="d-flex justify-content-sm-center"
          style={{
            marginBottom: "150px",
            paddingLeft: "100",
          }}
        >
          <div>
            <Row>
              <Col xs={12}>
                <AboutUsHeading />
              </Col>
              <Col xs={12}>
                <AboutUsBody />
              </Col>
              <Col xs={12}>
                <AboutUsLearnMoreLink href="#not-typical">
                  Learn More
                </AboutUsLearnMoreLink>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          xs={12}
          lg={6}
          className="d-sm-flex justify-content-sm-center"
          style={{ marginBottom: "150px" }}
        >
          <AboutUsImage fluid src={image} />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
